import {Injectable} from '@angular/core';
import {
    Router,
    CanActivate,
    NavigationExtras,
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivateChild,
    RouterStateSnapshot, UrlTree
} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Patient} from '../model/Patient';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class ParentGuardService implements CanActivate {
    private patient: Patient;

    constructor(
        private router: Router,
        private store: Store<{ patient: Patient }>
    ) {
        store.pipe(select('patient')).subscribe((patient: Patient) => {
            this.patient = patient
        })
    }

    async canActivate(route: ActivatedRouteSnapshot) {
        if (!this.patient) {
            await this.router.navigate(['patients'])
            return false;
        } else {
            return true;
        }
    }
}
