import BaseService from './BaseService';
import {Injectable} from '@angular/core';
import {Patient} from '../model/Patient';
import {Caregiver} from '../model/Caregiver';
import {OperationCenter} from '../model/OperationCenter';
import {Hub} from '../model/Hub';
import {Doctor} from '../model/Doctor';
import {Asl} from '../model/Asl';
import {LoggedUser} from '../model/LoggedUser';
import {User} from '../model/User';
import {DeviceType} from '../model/DeviceType';
import {DeviceItem} from '../model/DeviceItem';
import {SupportedMeasurementGroup} from '../model/SupportedMeasurementGroup';
import {MontlyMeasurements} from '../model/MontlyMeasurements';
import {YearlyThresholdsMeasurements} from '../model/YearlyThresholdsMeasurements';
import {HubTipology} from '../model/HubTipology';
import {GroupMeasurement} from '../model/GroupMeasurement';
import {HelpRequest} from '../model/HelpRequest';
import {Kit} from '../model/Kit';
import {Contract} from '../model/Contract';
import {Pack} from '../model/Pack';
import {Notification} from '../model/Notification';

@Injectable({
    providedIn: 'root',
})

export class ApiService extends BaseService {

    public async login(loginObj: any, force: boolean): Promise<LoggedUser> {
        const response = await this.post<LoggedUser>(`login?force=${force}`, loginObj).toPromise();

        return response;
    }

    // Crud User
    public async getAllUsers(all: boolean): Promise<User[]> {
        const response = await this.get<User[]>(`api/user?all=${all}`).toPromise();

        return response;
    }

    public async newUser(user: User): Promise<any> {
        const response = await this.post<any>(`api/user`, user).toPromise();

        return response;
    }

    public async editUser(user: User): Promise<any> {
        const response = await this.put<any>(`api/user`, user).toPromise();

        return response;
    }

    public async deleteUser(username: string): Promise<any> {
        const response = await this.put<any>(`api/user?username=${username}`).toPromise();

        return response;
    }

    // CRUD PATIENT
    public async getAllPatient(all: boolean): Promise<Patient[]> {
        const response = await this.get<Patient[]>(`api/patient?all=${all}`).toPromise();

        return response;
    }

    public async getPatientById(id: number): Promise<any[]> {
        const response = await this.get<any[]>(`api/patient?id=${id}`).toPromise();

        return response;
    }

    public async getPatientByCF(cf: string): Promise<any[]> {
        const response = await this.get<any[]>(`api/patient?cf=${cf}`).toPromise();

        return response;
    }

    public async newPatient(patient: Patient): Promise<any> {
        const response = await this.post<any>(`api/patient`, patient).toPromise();

        return response;
    }

    public async deletePatient(id: number): Promise<any> {
        const response = await this.delete<any>(`api/patient?id=${id}`).toPromise();

        return response;
    }

    public async editPatient(patient: Patient): Promise<any> {
        const response = await this.put<any>(`api/patient`, patient).toPromise();

        return response;
    }

    // CRUD ASL
    public async getAslById(id: number): Promise<Asl> {
        const response = await this.get<Asl>(`api/asl?id=${id}`).toPromise();

        return response;
    }

    public async getAllASLs(all: boolean): Promise<Asl[]> {
        const response = await this.get<Asl[]>(`api/asl?all=${all}`).toPromise();

        return response;
    }

    public async newASL(asl: Asl): Promise<any> {
        const response = await this.post<any[]>(`api/asl`, asl).toPromise();

        return response;
    }

    public async deleteAsl(id: number): Promise<any> {
        const response = await this.delete<any>(`api/asl?id=${id}`).toPromise();

        return response;
    }

    public async editAsl(asl: Asl): Promise<any> {
        const response = await this.put<any>(`api/asl`, asl).toPromise();

        return response;
    }

    // Crud Dottore
    public async getDoctorById(id: number): Promise<any> {
        const response = await this.get<any>(`api/doctor?id=${id}`).toPromise();

        return response;
    }

    public async newDoctor(doctor: Doctor): Promise<any> {
        const response = await this.post<any>(`api/doctor`, doctor).toPromise();

        return response;
    }

    public async editDoctor(doctor: Doctor): Promise<any> {
        const response = await this.put<any>(`api/doctor`, doctor).toPromise();

        return response;
    }

    public async getAllDoctor(all: boolean): Promise<Doctor[]> {
        const response = await this.get<Doctor[]>(`api/doctor?all=${all}`).toPromise();

        return response;
    }

    public async deleteDoctor(id: number): Promise<any> {
        const response = await this.delete<any>(`api/doctor?id=${id}`).toPromise();

        return response;
    }

    // CRUD HUB
    public async getAllHubs(all: boolean): Promise<Hub[]> {
        const response = await this.get<Hub[]>(`api/hub?all=${all}`).toPromise();

        return response;
    }

    public async getHubBySN(serialNumber: string): Promise<Hub[]> {
        const response = await this.get<Hub[]>(`api/hub?serial_number=${serialNumber}`).toPromise();

        return response;
    }

    public async newHub(hub: Hub): Promise<any> {
        const response = await this.post<any>(`api/hub`, hub).toPromise();

        return response;
    }

    async editHub(hub: Hub): Promise<any> {
        const response = await this.put<any>(`api/hub/edit`, hub).toPromise();

        return response;
    }

    async getHubTipology(): Promise<HubTipology[]> {
        const response = await this.get<HubTipology[]>(`api/hub/tipology?only_hardware=${false}`).toPromise();

        return response;
    }

    async getHubTipologyById(id): Promise<HubTipology> {
        const response = await this.get<HubTipology>(`api/hub_tipology?id=${id}`).toPromise();

        return response;
    }

    async getFreeHub(): Promise<Hub[]> {
        const response = await this.get<Hub[]>(`api/hub/free`).toPromise();

        return response;
    }

    public async deleteHub(mac: string): Promise<any> {
        const response = await this.delete<any>(`api/hub?serial_number=${mac}`).toPromise();

        return response;
    }

    // Crud Caregivers
    public async newCaregiver(caregiver: Caregiver): Promise<any> {
        const response = await this.post<any>(`api/patient/caregiver`, caregiver).toPromise();

        return response;
    }

    public async deleteCaregiver(id: number): Promise<any> {
        const response = await this.delete<any>(`api/patient/caregiver?id=${id}`).toPromise();

        return response;
    }

    public async editCaregiver(caregiver: Caregiver): Promise<any> {
        const response = await this.put<any>(`api/patient/caregiver`, caregiver).toPromise();

        return response;
    }

    // CRUD Operation Center
    public async getOperationCenterById(id: number): Promise<OperationCenter> {
        const response = await this.get<OperationCenter>(`api/operation_center?id=${id}`).toPromise();

        return response;
    }

    public async getAllOC(all: boolean): Promise<OperationCenter[]> {
        const response = await this.get<OperationCenter[]>(`api/operation_center?all=${all}`).toPromise();

        return response;
    }

    public async newOC(oc: OperationCenter): Promise<any> {
        const response = await this.post<any>(`api/operation_center`, oc).toPromise();

        return response;
    }

    public async editOC(oc: OperationCenter): Promise<any> {
        const response = await this.put<any>(`api/operation_center`, oc).toPromise();

        return response;
    }

    public async deleteOC(id: number): Promise<any> {
        const response = await this.delete<any>(`api/operation_center?id=${id}`).toPromise();

        return response;
    }

    // CRUD Users
    public async getUsers(id: number): Promise<OperationCenter> {
        const response = await this.get<OperationCenter>(`api/operation_center?id=${id}`).toPromise();

        return response;
    }

    public async addUser(user: any): Promise<any> {
        const response = await this.post<any>(`api/user`, user).toPromise();

        return response;
    }

    // CRUD Devices Type
    public async getAllDevicesType(all: boolean): Promise<DeviceType[]> {
        const response = await this.get<DeviceType[]>(`api/device_type?all=${all}`).toPromise();

        return response;
    }

    public async getDevicesTypeById(id: number): Promise<DeviceType> {
        const response = await this.get<DeviceType>(`api/device_type?id=${id}`).toPromise();

        return response;
    }

    public async newDeviceType(deivce: DeviceType): Promise<any> {
        const response = await this.post<any>(`api/device_type`, deivce).toPromise();

        return response;
    }

    public async editDeviceType(deivce: DeviceType): Promise<any> {
        const response = await this.put<any>(`api/device_type`, deivce).toPromise();

        return response;
    }

    public async deleteType(id: number): Promise<any> {
        const response = await this.delete<any>(`api/device_type?id=${id}`).toPromise();

        return response;
    }

    // CRUD Devices Item
    public async getAllDevicesItem(all: boolean): Promise<DeviceItem[]> {
        const response = await this.get<DeviceItem[]>(`api/bluetooth_item?all=${all}`).toPromise();

        return response;
    }

    public async getDevicesItemByType(id: number): Promise<DeviceItem[]> {
        const response = await this.get<DeviceItem[]>(`api/bluetooth_item?type=${id}`).toPromise();

        return response;
    }

    public async getFreeDevicesItem(): Promise<DeviceItem[]> {
        const response = await this.get<DeviceItem[]>(`api/bluetooth_item/free`).toPromise();

        return response;
    }

    public async newDeviceItem(device: DeviceItem): Promise<any> {
        const response = await this.post<any>(`api/bluetooth_item`, device).toPromise();

        return response;
    }

    public async editDeviceItem(device: DeviceItem): Promise<any> {
        const response = await this.put<any>(`api/bluetooth_item`, device).toPromise();

        return response;
    }

    public async deleteItem(btAddress: string): Promise<any> {
        const response = await this.delete<any>(`api/bluetooth_item?serial_number=${btAddress}`).toPromise();

        return response;
    }

    // CRUD Measurement

    public async getAllMeasurementsByPatientId(patientId: number): Promise<SupportedMeasurementGroup[]> {
        const response = await this.get<SupportedMeasurementGroup[]>(`api/measurement?patient=${patientId}`).toPromise();

        return response;
    }
    
    public async getMontlyMeasurements(patientId: number, month: number, year: number): Promise<MontlyMeasurements[]> {
        const response = await this.get<MontlyMeasurements[]>(`api/measurement/group/montly?patient=${patientId}&month=${month}&year=${year}`).toPromise();

        return response;
    }

    public async getAllMeasurementsById(id: number): Promise<SupportedMeasurementGroup> {
        const response = await this.get<SupportedMeasurementGroup>(`api/measurement?id=${id}`).toPromise();

        return response;
    }

    public async getAllMeasurements(all: boolean): Promise<SupportedMeasurementGroup[]> {
        const response = await this.get<SupportedMeasurementGroup[]>(`api/measurement?all=${all}`).toPromise();

        return response;
    }

    public async addMeasurement(measurement: any): Promise<any> {
        const response = await this.post<any>(`api/measurement`, measurement).toPromise();

        return response;
    }

    public async editMeasurement(measurement: any): Promise<any> {
        const response = await this.put<any>(`api/measurement`, measurement).toPromise();

        return response;
    }

    public async deleteMeasurement(id: number): Promise<any> {
        const response = await this.delete<any>(`api/measurement?id=${id}`).toPromise();

        return response;
    }

    // CRUD Group Measurement
    public async getAllGroupMeasurementsByPatientId(patientId: number): Promise<GroupMeasurement[]> {
        const response = await this.get<GroupMeasurement[]>(`api/measurement/group?patient=${patientId}`).toPromise();

        return response;
    }

    public async getAllGroupMeasurements(all: boolean): Promise<GroupMeasurement[]> {
        const response = await this.get<GroupMeasurement[]>(`api/measurement/group?all=${all}`).toPromise();

        return response;
    }

    public async getGroupMeasurementsById(id: number): Promise<GroupMeasurement> {
        const response = await this.get<GroupMeasurement>(`api/measurement/group?id=${id}`).toPromise();

        return response;
    }

    public async addGroupMeasurement(measurement: any): Promise<any> {
        const response = await this.post<any>(`api/measurement/group`, measurement).toPromise();

        return response;
    }

    public async editGroupMeasurement(measurement: any): Promise<any> {
        const response = await this.put<any>(`api/measurement/group`, measurement).toPromise();

        return response;
    }

    public async deleteGroupMeasurement(id: number): Promise<any> {
        const response = await this.delete<any>(`api/measurement/group?id=${id}`).toPromise();

        return response;
    }
    
    public async getYearlyThresholdsMeasurements(patientId: number, groupId: number): Promise<YearlyThresholdsMeasurements> {
        const response = await this.get<YearlyThresholdsMeasurements>(`api/measurement/group/thresholds_yearly?patient=${patientId}&group=${groupId}`).toPromise();
        
        return response;
    }

    // CRUD Notifications

    public async getNotification(id: number): Promise<Notification[]> {
        const response = await this.get<Notification[]>(`api/notification?patient=${id}`).toPromise();

        return response;
    }

    public async sendOneShotNotification(patient: number, text: string): Promise<any> {
        const response = await this.post<any>(`api/notification`, {
            active: true,
            patient,
            text
        }).toPromise();

        return response;
    }

    public async sendRecursiveNotification(notification: any): Promise<any> {
        const response = await this.post<any>(`api/notification`, notification).toPromise();

        return response;
    }

    public async deleteNotification(id: number) {
        const response = await this.delete<any>(`api/notification?id=${id}`).toPromise();

        return response;
    }

    // Verifica codice fiscale

    public async verifcationCF(cf: string): Promise<any> {
        const response = await this.get<any>(`api/cf?cf=${cf}`).toPromise();

        return response;
    }

    // CRUD Warning
    public async getAllWarnings(oc: any): Promise<HelpRequest[]> {
        const response = await this.get<HelpRequest[]>(`api/warning?oc=${oc}`).toPromise();

        return response;
    }
    
    public async getWarnings(oc: any, from: number, to: number): Promise<HelpRequest[]> {
        const response = await this.get<HelpRequest[]>(`api/warning?from=${from}&to=${to}&oc=${oc}`).toPromise();

        return response;
    }

    public async editWarning(warning): Promise<any> {
        const response = await this.put<any>(`api/warning`, warning).toPromise();

        return response;
    }

    // CRUD Contract
    public async getContract(): Promise<Contract[]> {
        const response = await this.get<Contract[]>(`api/contract?patient=${20}`).toPromise();

        return response;
    }

    public async getContractById(id): Promise<Contract> {
        const response = await this.get<Contract>(`api/contract?id=${id}`).toPromise();

        return response;
    }

    public async newContract(contract: Contract): Promise<any[]> {
        const response = await this.post<any[]>(`api/contract`, contract).toPromise();

        return response;
    }

    public async editContract(contract: Contract): Promise<any[]> {
        const response = await this.put<any[]>(`api/contract`, contract).toPromise();

        return response;
    }

    // CRUD Contract Pack
    public async getIncompleteContractPack(): Promise<Pack[]> {
        const response = await this.get<Pack[]>(`api/contract/pack?filter=INCOMPLETE`).toPromise();

        return response;
    }

    public async getCompleteContractPack(): Promise<Pack[]> {
        const response = await this.get<Pack[]>(`api/contract/pack?filter=COMPLETE`).toPromise();

        return response;
    }

    public async getInCompleteDeliveredContractPack(): Promise<Pack[]> {
        const response = await this.get<Pack[]>(`api/contract/pack?filter=INCOMPLETE_DELIVERED`).toPromise();

        return response;
    }

    public async completePack(pack: Pack) {
        const response = await this.put<Pack[]>(`api/contract/pack`, pack).toPromise();

        return response;
    }

    public async getPackMissingHW(id) {
        const response = await this.get<any>(`api/contract/pack/missing_hardware?id=${id}`).toPromise();

        return response;
    }

    public async deletePack(id): Promise<Kit> {
        const response = await this.delete<Kit>(`api/pack?id=${id}`).toPromise();

        return response;
    }

    public async sendPack(id): Promise<Kit> {
        const response = await this.put<Kit>(`api/contract/pack/sent?id=${id}`).toPromise();

        return response;
    }

    // CRUD Kit
    public async getAllKit(all): Promise<Kit[]> {
        const response = await this.get<Kit[]>(`api/kit?all=${all}`).toPromise();

        return response;
    }

    public async newKit(kit): Promise<Kit> {
        const response = await this.post<Kit>(`api/kit`, kit).toPromise();

        return response;
    }

    public async editKit(kit): Promise<Kit> {
        const response = await this.put<Kit>(`api/kit`, kit).toPromise();

        return response;
    }

    public async deleteKit(id): Promise<Kit> {
        const response = await this.delete<Kit>(`api/kit?id=${id}`).toPromise();

        return response;
    }
}
