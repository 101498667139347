import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import cronstrue from 'cronstrue/i18n';

@Component({
    selector: 'mad-cron',
    templateUrl: './cron.component.html',
    styleUrls: ['./cron.component.scss']
})

/**
 * cron string: minutes hour day month week
 */
export class CronComponent implements OnInit {
    minutes = []
    fromHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]
    toHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]
    // tslint:disable-next-line:no-output-rename
    @Output('cronFromHour') cronFromHourEmitter: EventEmitter<string> = new EventEmitter<string>();
    // tslint:disable-next-line:no-output-rename
    @Output('cronToHour') cronToHourEmitter: EventEmitter<string> = new EventEmitter<string>();
    // tslint:disable-next-line:no-output-rename
    @Output('cronMinutes') cronMinutesEmitter: EventEmitter<string> = new EventEmitter<string>();
    @Input() cron: string;
    cronMinutes = null
    cronFromHour = null
    cronToHour = null

    constructor() {
    }

    ngOnInit() {
        for (let i = 1; i < 60; i++) {
            this.minutes.push(i)
        }
        if (this.cron) {
            let fromTo;
            [this.cronMinutes, fromTo] = this.cron.split(' ');
            [this.cronFromHour, this.cronToHour] = fromTo.split('-')
            this.cronMinutes = this.cronMinutes.substr(this.cronMinutes.indexOf('/') + 1,
                this.cronMinutes.length - 1)
        }
    }

    hourFromSelected(hour) {
        this.cronFromHour = hour;
        this.toHours = this.toHours.filter(h => h > hour)
        this.cronFromHourEmitter.emit(this.cronFromHour)
    }

    hourToSelected(hour) {
        this.cronToHour = hour;
        this.cronToHourEmitter.emit(this.cronToHour)
    }

    everyMinutes(minute: any) {
        this.cronMinutes = minute
        this.cronMinutesEmitter.emit(this.cronMinutes)

    }

    convert() {
        console.log(cronstrue.toString('*/5 4-4 * * *', {locale: 'it'}))
    }
}
