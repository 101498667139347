import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import {PatientsResolver} from './pages/patients/patientsResolver';
import {DoctorsResolver} from './pages/doctors/doctorsResolver';
import {HubsResolver} from './pages/hubs/hubsResolver';
import {AslsResolver} from './pages/asls/aslsResolver';
import {OperativeCenterResolver} from './pages/operative_center/operativeCenterResolver';
import {UsersResolver} from './pages/users/usersResolver';
import {DevicesResolver} from './pages/devices/devicesResolver';
import {ParentGuardService} from './guardie/ParentGuardService';
import {MeasurementsResolver} from './pages/measurements/measurementsResolver';
import {AuthGuardService} from './guardie/AuthGuardService';
import {RoleGuardService} from './guardie/RoleGuardService';
import {GroupMeasurementsResolver} from './pages/group_measurements/group_measurementsResolver';
import {WarningsResolver} from './pages/warnings/warningsResolver';
import {KitsResolver} from './pages/kits/kitsResolver';
import {PacksResolver} from './pages/packs/packsResolver';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: './pages/login/login.module#LoginModule'
    },
    {
        path: '',
        component: AdminLayoutComponent,
        canActivateChild: [AuthGuardService],
        children: [
            {
                path: 'patients',
                // component: PatientsComponent,
                children: [
                    {
                        path: '',
                        resolve: {
                            patients: PatientsResolver
                        },
                        loadChildren: './pages/patients/patients.module#PatientsModule'

                    },
                    {
                        path: 'caregivers',
                        canActivate: [ParentGuardService],
                        loadChildren: './pages/caregivers/caregivers.module#CaregiversModule'

                    },
                    {
                        path: 'dashboard',
                        canActivate: [ParentGuardService],
                        loadChildren: './pages/dashboard/dashboard.module#DashboardModule'
                    }
                ]
            },
            {
                path: 'doctors',
                resolve: {
                    doctors: DoctorsResolver
                },
                loadChildren: './pages/doctors/doctors.module#DoctorsModule'
            },
            {
                path: 'devices',
                resolve: {
                    devices: DevicesResolver
                },
                canActivate: [RoleGuardService],
                loadChildren: './pages/devices/devices.module#DevicesModule'
            },
            {
                path: 'asl',
                resolve: {
                    asls: AslsResolver
                },
                loadChildren: './pages/asls/asls.module#AslsModule'
            },
            {
                path: 'operation_center',
                resolve: {
                    oc: OperativeCenterResolver
                },
                loadChildren: './pages/operative_center/operative_center.module#OperativeCenterModule'
            },
            {
                path: 'warnings',
                resolve: {
                    warnings: WarningsResolver
                },
                loadChildren: './pages/warnings/warnings.module#WarningsModule'
            },
            {
                path: 'hubs',
                resolve: {
                    hubs: HubsResolver
                },
                canActivate: [RoleGuardService],
                loadChildren: './pages/hubs/hubs.module#HubsModule'
            },
            {
                path: 'users',
                resolve: {
                    users: UsersResolver
                },
                canActivate: [RoleGuardService],
                loadChildren: './pages/users/users.module#UsersModule'
            },
            {
                path: 'measurements',
                resolve: {
                    measurements: MeasurementsResolver
                },
                loadChildren: './pages/measurements/measurements.module#MeasurementsModule'
            },
            {
                path: 'group_measurements',
                resolve: {
                    measurements: GroupMeasurementsResolver
                },
                loadChildren: './pages/group_measurements/group_measurements.module#GroupMeasurementsModule'
            },
            {
                path: 'kits',
                resolve: {
                    kits: KitsResolver
                },
                loadChildren: './pages/kits/kits.module#KitsModule'
            },
            {
                path: 'packs',
                resolve: {
                    packs: PacksResolver
                },
                loadChildren: './pages/packs/packs.module#PacksModule'
            },
            {
                path: 'dash_prova',
                loadChildren: './pages/dashboardProva/dashboardProva.module#DashboardProvaModule'
            },
            {
                path: 'fine_linea',
                loadChildren: './pages/fine_linea/fine_linea.module#FineLineaModule'
            }
        ]
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: 'examples',
                loadChildren:
                    './layouts/auth-layout/auth-layout.module#AuthLayoutModule'
            }
        ]
    },
    {
        path: '**',
        redirectTo: 'login'
    }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, {
            useHash: true
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
