import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {cleanError} from '../actions/error.action';
import {Store} from '@ngrx/store';
import {cleanSuccess} from '../actions/success.action';
import {cleanWarning} from '../actions/warning.action';

@Injectable({
    providedIn: 'root',
})

export class ToastService {
    constructor(
        private toastr: ToastrService,
        private store: Store,
    ) {
    }

    error(error) {
        this.toastr.show(
            `<i class="alert-icon fas fa-exclamation" data-notify="icon"></i>
                      <div class="alert-text"</div>
                      <span class="alert-title" data-notify="title">Errore</span>
                      <span data-notify="message">${error}</span></div>`,
            '',
            {
                disableTimeOut: false,
                timeOut: 3000,
                closeButton: true,
                enableHtml: true,
                tapToDismiss: true,
                titleClass: 'alert-title',
                positionClass: 'toast-top-center',
                toastClass:
                    'ngx-toastr alert alert-dismissible alert-warning alert-notify'
            }
        ).onHidden
            .subscribe((e) => this.store.dispatch(cleanError()));
    }

    success(mex: string) {
        this.toastr.show(
            `
            <span class=" alert-icon"> 
                <i class=" ni ni-like-2"> </i> 
            </span>
            <span class=" alert-text">
              ${mex}
            </span>`,
            '',
        {
            disableTimeOut: false,
            timeOut: 2500,
            closeButton: true,
            enableHtml: true,
            tapToDismiss: true,
            positionClass: 'toast-top-right',
            toastClass: 'ngx-toastr alert alert-dismissible alert-success alert-notify'
        }).onHidden.subscribe(() => this.store.dispatch(cleanSuccess()));
    }

    warning(warning) {
        this.toastr.show(
            `<i class="alert-icon fas fa-exclamation" data-notify="icon"></i>
                      <div class="alert-text"</div>
                      <span class="alert-title" data-notify="title">Attenzione</span>
                      <span data-notify="message">${warning}</span></div>`,
            '',
            {
                disableTimeOut: true,
                timeOut: 3000,
                closeButton: true,
                enableHtml: true,
                tapToDismiss: true,
                titleClass: 'alert-title',
                positionClass: 'toast-top-center',
                toastClass:
                    'ngx-toastr alert alert-dismissible alert-warning alert-notify'
            }
        ).onHidden
            .subscribe((e) => this.store.dispatch(cleanWarning()));
    }

}
