import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ApiService} from '../../service/ApiService';
import {Patient} from '../../model/Patient';
import {CasePascalPipe} from '../../pipe/CasePascalPipe';

@Injectable({providedIn: 'root'})
export class PatientsResolver implements Resolve<any[]> {
    constructor(private service: ApiService) {
    }

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<any> {
        try {
            let patients = await this.service.getAllPatient(true)
            patients.filter(p => !(p.doctor instanceof Object) && p.doctor).map(async p => {
                p.doctor = await this.service.getDoctorById(p.doctor)
            })
            patients.filter(p => !(p.asl instanceof Object)).map(async p => {
                p.asl = await this.service.getAslById(p.asl)
            })
            patients.filter(p => p.activeContract && p.activeContract.pack.hub).map(async p => {
                p.hub = await this.service.getHubBySN(p.activeContract.pack.hub)
            })
            /*patients.filter(p => p.contracts && p.contracts.length > 0).map(async p => {
                for (let i = 0; i < p.contracts.length; i++) {
                    p.contracts[i] = await this.service.getContractById(p.contracts[i])
                }
            })*/
            patients = patients.map(
                (p: Patient) => {
                    const pipe = new CasePascalPipe()
                    p.name = pipe.transform(p.name)
                    p.surname = pipe.transform(p.surname)
                    return p
                })
            console.log(patients)
            return patients
        } catch (e) {
            return []
        }
    }
}
