import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ApiService} from '../../service/ApiService';

@Injectable({providedIn: 'root'})
export class MeasurementsResolver implements Resolve<any> {
    constructor(private service: ApiService) {
    }

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<any> {
        try {
            const measurements = await this.service.getAllMeasurements(true)
            console.log(measurements)
            return measurements
        } catch (e) {
            return []
        }
    }
}
