import {AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, Event, NavigationStart, NavigationEnd, NavigationExtras, NavigationError} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {addUser} from './actions/logged_user.action';
import jwt_decode from 'jwt-decode';
import {LoggedUser} from './model/LoggedUser';
import {ToastService} from './service/ToastService';
import {WebSocketBaseService} from './service/WebSocketBaseService';
import {wsFineLinea, wsWarning} from '../env';
import {HelpRequest} from './model/HelpRequest';
import {addWarning, saveWarning} from './actions/warning.action';
import {ApiService} from './service/ApiService';
import * as moment from 'moment'
import {cloneDeep, groupBy} from 'lodash';
import {AnimationOptions} from 'ngx-lottie';
import {esLocale} from 'ngx-bootstrap';

@Component({
    selector: 'mad-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    helpRequests: HelpRequest[] = []
    public loading = false;
    options: AnimationOptions = {
        path: '/assets/img/lottie/loader.json',
        loop: true
    };
    callLoading;
    login = true;
    hide = false;

    constructor(
        private router: Router,
        private socketService: WebSocketBaseService,
        private apiService: ApiService,
        private store: Store<{
            error: string, success: string,
            warning: string, loggeduser: LoggedUser,
            loading: boolean
        }>,
        public toastService: ToastService,
        private cdr: ChangeDetectorRef
    ) {

        store.pipe(select('error')).subscribe(
            async (error: string) => {
                if (error) {
                    this.toastService.error(error)
                }
            }
        )

        store.pipe(select('loading')).subscribe(
            async (loading: boolean) => {
                this.callLoading = loading
            }
        )

        store.pipe(select('success')).subscribe(
            async (success: string) => {
                if (success) {
                    this.toastService.success(success)
                }
            }
        )

        /*store.pipe(select('warning')).subscribe(
            async (warning: string) => {
                if (warning) {
                    this.toastService.warning(warning)
                }
            }
        )*/

        store.pipe(select('loggeduser')).subscribe((loggeduser: LoggedUser) => {
            if (loggeduser && loggeduser.role !== 'WORKER') {
                this.login = false
                this.socketService.getData().subscribe(async (res: HelpRequest | HelpRequest[]) => {
                    if (res instanceof Array && res.length >= 0) {
                        res = res.reverse()
                        if (res.length > 0) {
                            res = res.filter(help => help.warning && help.status === 'OPEN')
                            this.loading = true
                            for (let i = res.length-1; i >= 0; i--) {
                                const help = res[i]
                                if (i !== res.length-1 && res[i].patient === res[i + 1].patient.id) {
                                    help.patient = res[i + 1].patient
                                } else {
                                    help.patient = await this.apiService.getPatientById(help.patient)
                                }
                                
                                console.log(help)
                                help.time = moment.unix(help.warning.time.epochSecond);
                                (help as any).new = false;
                                this.helpRequests.push(help)
                            }
                            this.loading = false
                        }
                    } else if (res instanceof Object) {
                        console.log('qui reealtime', res)
                        this.hide = false
                        const help = res as any
                        if (help && (help.patient || help.id)) {
                            help.patient = await this.apiService.getPatientById(help.id ? help.id : help.patient)
                            help.type = help.hasOwnProperty('phrase') ? 'PatientHelpRequestPoint' : 'PatientMeasurementPoint';
                            (help as any).new = true;
                        }
                        /*help.warning = {
                            time: null
                        }
                        help.warning.time = moment(help.time)*/
                        help.time = moment(help.timestamp)
                        this.helpRequests.unshift(help)
                        this.cdr.detectChanges()
                        /* this.store.dispatch(
                             // addWarning({newWarning: `Allarme per ${help.patient.name} ${help.patient.surname}`}))
                             addWarning({newWarning: help}))*/
                    }
                })
            } else {
                this.login = true
                this.helpRequests = []
            }
        })

        // @ts-ignore
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                // Show loading indicator
                window.scrollTo(0, 0)
            }

            if (event instanceof NavigationEnd) {
                // Hide loading indicator
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
                console.log(event.error);
            }
        });

    }

    async ngOnInit() {
        const resp = await localStorage.getItem('auth_data')
        if (resp) {
            const user = JSON.parse(resp) as LoggedUser
            const decoded: any = jwt_decode(user.token);
            user.username = decoded.sub
            console.log(user)
            if (user.role === 'WORKER') {
                this.socketService.connectFineLinea(wsFineLinea)
                await this.router.navigate(['fine_linea'])
            } else {
                this.socketService.connectWarn(wsWarning)
                this.router.navigate(['patients'])
            }
            this.store.dispatch(addUser({newUser: user}))
        } else {
            this.router.navigate(['login'])
        }
    }
    
    removeHelpRequest(index) {
        this.helpRequests = this.helpRequests.filter(h => h.id != index);
    }

    goToWarning(index) {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                selectedHelp: this.helpRequests[index].id
                
            }
        };
        
        // this.helpRequests = this.helpRequests.filter(((h, i) => i !== index));
        // this.store.dispatch(saveWarning({warnings: this.helpRequests}));
        this.router.navigate(['warnings'], navigationExtras);
        this.hide = true;
        
    }
}
