import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';
import {apiBaseUrl} from '../../env';

export interface KeyValue {
    key: string;
    value?: string;
}

@Injectable({
    providedIn: 'root',
})

export default class BaseService {

    constructor(private router: Router, public httpclient: HttpClient) {
    }

    protected get<T>(path: string, query?: KeyValue[], headers?: HttpHeaders): Observable<T> {
        return this.httpclient.get<T>(`${apiBaseUrl}${path}`);
    }

    protected delete<T>(path: string, query?: KeyValue[], headers?: KeyValue[]): Observable<T> {
        return this.httpclient.delete<T>(`${apiBaseUrl}${path}`);
    }

    /*
    protected head<T>(path: string, query?: KeyValue[], headers?: KeyValue[]): Observable<angularResponse<T>> {
        console.debug("HEAD");
        return angularInstance.head(url.resolve(path, resolveQueryParams(query)));
    } */
    public post<T>(path: string, data?: any, query?: KeyValue[], headers?: KeyValue[]): Observable<any> {
        return this.httpclient.post(`${apiBaseUrl}${path}`, data);
    }

    public postCheckIn<T>(path: string, data?: any, query?: KeyValue[], headers?: KeyValue[]): Observable<any> {
        return this.httpclient.post(`${apiBaseUrl}${path}`, data);
    }

    public putCheckIn<T>(path: string, data?: any, query?: KeyValue[], headers?: KeyValue[]): Observable<any> {
        return this.httpclient.put(`${apiBaseUrl}${path}`, data);
    }

    protected put<T>(path: string, data?: any, query?: KeyValue[], headers?: KeyValue[]): Observable<any> {
        return this.httpclient.put(`${apiBaseUrl}${path}`, data, );
    }

    /*  protected patch<T>(path: string, data?: any, query?: KeyValue[], headers?: KeyValue[]): Observable<angularResponse<T>> {
        console.debug("PATCH");
        return angularInstance.patch(url.resolve(path, resolveQueryParams(query)), data);
    } */
}
