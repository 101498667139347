import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ApiService} from '../../service/ApiService';
import {Pack} from '../../model/Pack';

@Injectable({providedIn: 'root'})
export class PacksResolver implements Resolve<Pack[]> {
    constructor(private service: ApiService) {
    }

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Pack[]> {
        try {
            let packs = await this.service.getIncompleteContractPack()
            packs.filter(p => !(p.patient instanceof Object) && p.patient).map(async p => {
                p.patient = await this.service.getPatientById(p.patient)
            })
            const packsIncompleteDeliver = await this.service.getInCompleteDeliveredContractPack()
            packsIncompleteDeliver.filter(p => !(p.patient instanceof Object) && p.patient).map(async p => {
                p.patient = await this.service.getPatientById(p.patient)
            })
            packs = packs.concat(packsIncompleteDeliver)
            return packs
        } catch (e) {
            return []
        }
    }
}
