import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'mad-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
    @Input() h5Text
    @Input() spanH5Text
    @Input() gradientColor
    @Input() icon
    @Input() spanTextColor
    @Input() spanIcon
    @Input() percentageText
    @Input() spanText

    constructor() {
    }

    ngOnInit() {
    }

}
