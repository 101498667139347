import {NgModule} from '@angular/core';
import {CasePascalPipe} from './CasePascalPipe';
import {FormatDate} from './FormatDate';
import {MeasurementType} from './MeasurementType';

@NgModule({
    imports: [],
    declarations: [
        CasePascalPipe,
        FormatDate,
        MeasurementType
    ],
    exports: [
        CasePascalPipe,
        FormatDate,
        MeasurementType
    ]
})

export class ApplicationPipesModule {
}
