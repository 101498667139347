import {Action, createReducer, on} from '@ngrx/store';
import {addWarning, cleanWarning, saveWarning} from '../actions/warning.action';
import {HelpRequest} from '../model/HelpRequest';

export const warning: HelpRequest[] = [];

// tslint:disable-next-line:variable-name
const _warningReducer = createReducer(warning,
    on(saveWarning, (state: any, {warnings}) => warnings),
    on(addWarning, (state: any, {newWarning}) => [...state, newWarning]),
    on(cleanWarning, (state: any) => null)
);

export function warningReducer(state: HelpRequest[] = warning, action: Action) {
    return _warningReducer(state, action);
}
