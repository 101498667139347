import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LoggedUser} from '../model/LoggedUser';
import {WebsocketResponse} from '../model/WebsocketResponse';
import * as moment from 'moment'
import {GroupMeasurement} from '../model/GroupMeasurement';
import {addSuccess} from '../actions/success.action';
import {Store} from '@ngrx/store';

@Injectable({
    providedIn: 'root',
})

export class WebSocketBaseService {


    // Our socket connection
    private socket: WebSocket;
    private socketPool: WebSocket[] = []

    constructor(
        private store: Store
    ) {
    }

    public connectDash(idPatient: number, measurement: GroupMeasurement, wsUrl: string) {
        // If you aren't familiar with environment variables then
        // you can hard code `environment.ws_url` as `http://localhost:5000`
        // @ts-ignore
        this.socket = new WebSocket(wsUrl)

        // const socketServer = new WebSocket('ws://localhost:5000')

        this.socket.addEventListener('open', (event) => {
            const startOfMonth = moment().clone().startOf('month');
            console.log('open', idPatient, measurement)
            this.socketPool.push(this.socket)
            if (JSON.parse(localStorage.getItem('auth_data')) && idPatient && measurement) {
                console.log(measurement.name)
                const authData = JSON.parse(localStorage.getItem('auth_data')) as LoggedUser;
                this.socket.send(JSON.stringify({
                    patient: idPatient,
                    token: authData.token,
                    from: moment().diff(startOfMonth, 'day'),
                    to: 0,
                    measurementGroup: measurement.name.toLowerCase()
                }));
            }
        });

        this.socket.addEventListener('close', (event) => {
            console.log('closedEvent ', event);
        });

        this.socket.onclose = (even) => {
            console.log('closedON ', even);
        }

        this.socket.onerror = (e) => {
            this.socketPool = this.socketPool.filter(s => !s.url.includes(wsUrl))
            this.connectDash(idPatient, measurement, wsUrl)
            console.log('errorrrr', e)
        }
    }

    public connectWarn(wsUrl: string) {
        // If you aren't familiar with environment variables then
        // you can hard code `environment.ws_url` as `http://localhost:5000`
        // @ts-ignore
        this.socket = new WebSocket(wsUrl)
        // const endOfMonth = moment().clone().endOf('month');

        // const socketServer = new WebSocket('ws://localhost:5000')

        this.socket.addEventListener('open', (event) => {
            this.socketPool.push(this.socket)
            if (JSON.parse(localStorage.getItem('auth_data'))) {
                const authData = JSON.parse(localStorage.getItem('auth_data')) as LoggedUser;
                this.socket.send(JSON.stringify({
                    token: authData.token,
                    from: 0,
                    to: 0,
                }));
            }
            console.log(this.socketPool)
        });

        this.socket.addEventListener('close', (event) => {
            console.log('closedEvent ', event);
        });

        this.socket.onclose = (even) => {
            console.log('closedON ', even);
        }

        this.socket.onerror = (e) => {
            this.socketPool = this.socketPool.filter(s => !s.url.includes(wsUrl))
            this.connectWarn(wsUrl)
            console.log('errorrrr', e)
        }
    }

    public connectFineLinea(wsUrl: string) {
        // If you aren't familiar with environment variables then
        // you can hard code `environment.ws_url` as `http://localhost:5000`
        // @ts-ignore
        this.socket = new WebSocket(wsUrl)
        // const endOfMonth = moment().clone().endOf('month');

        // const socketServer = new WebSocket('ws://localhost:5000')

        this.socket.addEventListener('open', (event) => {
            this.socketPool.push(this.socket)
            if (JSON.parse(localStorage.getItem('auth_data'))) {
                const authData = JSON.parse(localStorage.getItem('auth_data')) as LoggedUser;
                this.socket.send(JSON.stringify({
                    token: authData.token,
                }));
            }
            console.log(this.socketPool)
        });

        this.socket.addEventListener('close', (event) => {
            console.log('closedEvent ', event);
            this.connectFineLinea(wsUrl)
        });

        this.socket.onclose = (even) => {
            console.log('closedON ', even);
            this.connectFineLinea(wsUrl)
        }

        this.socket.onerror = (e) => {
            this.socketPool = this.socketPool.filter(s => !s.url.includes(wsUrl))
            this.connectFineLinea(wsUrl)
            console.log('errorrrr', e)
        }
    }

    public getData() {
        if (this.socket) {
            return new Observable((observer) => {
                this.socket.addEventListener('message', (event) => {
                    const response = JSON.parse(event.data) as WebsocketResponse
                    if (response.status === 403) {
                        return
                    }
                    if (response.data instanceof Array) {
                        const data = response.data.map((res: any) => {
                            if (res.time && !res.time.epochSecond) {
                                res.time = moment.unix(res.time)
                            } else {
                                res.time = moment.unix(res.time.epochSecond)
                            }
                            return res
                        })
                        observer.next(data);
                    } else {
                        console.log('realtime', response.data)
                        console.log(response.data.time)
                        observer.next(response.data);
                    }
                });
            });
        }
    }

    public emit(idPatient: number, measurementGroup: string, dayFrom: number, dayTo: number) {
        if(this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(JSON.stringify({
                patient: idPatient,
                from: dayFrom,
                to: dayTo,
                measurementGroup: measurementGroup.toLowerCase()
            }));
        } else {
            console.log('WEBSOCKET NOT READY!!!');
        }
    }

    public emitFineLinea(body: any) {
        console.log(body)
        this.socket.send(JSON.stringify(body));
    }

    public getDataFineLinea() {
        if (this.socket) {
            return new Observable((observer) => {
                this.socket.addEventListener('message', (event) => {
                    const response = JSON.parse(event.data) as WebsocketResponse
                    console.log(response.data)
                    if (response.status === 400) {
                        return
                    }
                    if (response.status === 201) {
                        observer.next(response.data)
                        this.store.dispatch(addSuccess({newSuccess: 'Azione effettuata con successo'}))
                    }


                    observer.next(response.data || response)

                });
            });
        }
    }

    public close(url?: string) {
        if (url) {
            this.socketPool.filter(s => s.url.includes(url)).map(s => {
                console.log(s)
                s.close()
            })
            this.socketPool = this.socketPool.filter(s => !s.url.includes(url))
        } else {
            this.socketPool.forEach(s => {
                console.log(s)
                s.close();
            });
            this.socketPool = []
        }
    }
}

