import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {BsDropdownModule} from 'ngx-bootstrap';
import {ToastrModule} from 'ngx-toastr';
import {TagInputModule} from 'ngx-chips';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {AppComponent} from './app.component';
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import {BackButtonDisableModule} from 'angular-disable-browser-back-button';
import {AppRoutingModule} from './app-routing.module';
import {ComponentsModule} from './components/components.module';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {patientReducer} from './reducers/patient.reducer';
import {Interceptor} from './interceptor/interceptor';
import {SocketIoConfig, SocketIoModule} from 'ngx-socket-io';
import {loggeduserReducer} from './reducers/loggedUser.reducer';
import {errorReducer} from './reducers/error.reducer';
import {successReducer} from './reducers/success.reducer';
import {warningReducer} from './reducers/warning.reducer';
import {ApplicationPipesModule} from './pipe/ApplicationPipes.module';
import {LottieModule} from 'ngx-lottie';
import {loading, loadingReducer} from './reducers/loading.reducer';
import player from 'lottie-web';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';

export function momentAdapterFactory() {
  return adapterFactory(moment);
};

const config: SocketIoConfig = {
    url: `wss://api.hub.madstudio.it/api`, options: {
        path: '/ws_api/measurements',

        auth: {prova: 'prova'},
        transports: ['websocket'],
        transportOptions: {
            websocket: {
                extraHeaders: {
                    authorization: 'prova'
                }
            }
        }
    }
};

export function playerFactory() {
    return player;
}

@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        SocketIoModule,
        ComponentsModule,
        RouterModule,
        BsDropdownModule.forRoot(),
        AppRoutingModule,
        ToastrModule.forRoot(),
        CollapseModule.forRoot(),
        TagInputModule,
        StoreModule.forRoot({
            patient: patientReducer,
            error: errorReducer,
            loggeduser: loggeduserReducer,
            success: successReducer,
            warning: warningReducer,
            loading: loadingReducer,
        }),
        StoreDevtoolsModule.instrument(),
        BackButtonDisableModule.forRoot({
            preserveScrollPosition: true
        }),
        ApplicationPipesModule,
        LottieModule.forRoot({player: playerFactory}),
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    ],
    declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent],
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: Interceptor,
        multi: true
    },],
    bootstrap: [AppComponent]
})
export class AppModule {
}
