import {Action, createReducer, on} from '@ngrx/store';
import {addSuccess, cleanSuccess} from '../actions/success.action';

export const success: string = null;

// tslint:disable-next-line:variable-name
const _successReducer = createReducer(success,
    on(addSuccess, (state: any, {newSuccess}) => newSuccess),
    on(cleanSuccess, (state: any) => null)
);

export function successReducer(state: string = success, action: Action) {
    return _successReducer(state, action);
}
