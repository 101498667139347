import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ApiService} from '../../service/ApiService';
import {DeviceType} from '../../model/DeviceType';

@Injectable({providedIn: 'root'})
export class DevicesResolver implements Resolve<DeviceType[]> {
    constructor(private service: ApiService) {
    }

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<DeviceType[]> {
        try {
            const devices = await this.service.getAllDevicesType(true)
            devices.map(async d => {
                for (let i = 0; i < d.supportedMeasurementGroup.length; i++) {
                    const sp = d.supportedMeasurementGroup[i];
                    if (!(sp instanceof Object)) {
                        d.supportedMeasurementGroup[i] =
                            await this.service.getGroupMeasurementsById(sp)
                    }
                }
            })
            return devices
        } catch (e) {
            return []
        }
    }
}
