import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ApiService} from '../../service/ApiService';
import {Doctor} from '../../model/Doctor';
import {CasePascalPipe} from '../../pipe/CasePascalPipe';
import {Kit} from '../../model/Kit';

@Injectable({providedIn: 'root'})
export class KitsResolver implements Resolve<Kit[]> {
    constructor(private service: ApiService) {
    }

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Kit[]> {
        try {
            const kits = await this.service.getAllKit(true)
            kits.filter(k => !(k.hubTipology instanceof Object) && k.hubTipology).map(async k => {
                k.hubTipology = await this.service.getHubTipologyById(k.hubTipology)
            })

            return kits
        } catch (e) {
            return []
        }
    }
}
