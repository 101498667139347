import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'casepascal'})
export class CasePascalPipe implements PipeTransform {

    transform(value: string): string {
        if (value) {
            return value.replace(/(\w)(\w*)/g, (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase());
        }

        return null;
    }
}
