import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'measurementType'})
export class MeasurementType implements PipeTransform {

    transform(value: string): string {
        if (value === 'PatientMeasurementPoint') {
            return 'Misurazione'
        }

        return 'Richiesta di aiuto';
    }
}
