import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ApiService} from '../../service/ApiService';
import {Observable, of} from 'rxjs';
import {Doctor} from '../../model/Doctor';
import {Patient} from '../../model/Patient';
import {CasePascalPipe} from '../../pipe/CasePascalPipe';

@Injectable({providedIn: 'root'})
export class DoctorsResolver implements Resolve<Doctor[]> {
    constructor(private service: ApiService) {
    }

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Doctor[]> {
        try {
            let doctors = await this.service.getAllDoctor(true)
            doctors.filter(d => !(d.asl instanceof Object) && d.asl).map(async d => {
                d.asl = await this.service.getAslById(d.asl)
            })
            doctors = doctors.map((d: Doctor) => {
                const pipe = new CasePascalPipe()
                d.name = pipe.transform(d.name)
                d.surname = pipe.transform(d.surname)
                return d
            })
            return doctors
        } catch (e) {
            return []
        }
    }
}
