import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ApiService} from '../../service/ApiService';
import {Hub} from '../../model/Hub';

@Injectable({providedIn: 'root'})
export class HubsResolver implements Resolve<any[]> {
    constructor(private service: ApiService) {
    }

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Hub[]> {
        try {
            const hubs: Hub[] = await this.service.getAllHubs(true)
            hubs.filter(h => !(h.patient instanceof Object) && h.patient).map(async h => {
                h.patient = await this.service.getPatientById(h.patient)
            })
            hubs.filter(h => !(h.operationCenter instanceof Object) && h.operationCenter).map(async h => {
                h.operationCenter = await this.service.getOperationCenterById(h.operationCenter)
            })
            return hubs
        } catch (e) {
            return []
        }
    }
}
