import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ApiService} from '../../service/ApiService';
import {OperationCenter} from '../../model/OperationCenter';

@Injectable({providedIn: 'root'})
export class OperativeCenterResolver implements Resolve<OperationCenter[]> {

    constructor(private service: ApiService) {
    }

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<OperationCenter[]> {
        try {
            const oc = await this.service.getAllOC(true)
            return oc
        } catch (e) {
            return []
        }
    }
}
