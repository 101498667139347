import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ROLES} from '../../../env';
import {select, Store} from '@ngrx/store';
import {Patient} from '../../model/Patient';
import {LoggedUser} from '../../model/LoggedUser';

const misc: any = {
    sidebar_mini_active: true
};

export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    isCollapsed?: boolean;
    isCollapsing?: any;
    children?: ChildrenItems[];
    role: string[]
}

export interface ChildrenItems {
    path: string;
    title: string;
    type?: string;
    collapse?: string;
    children?: ChildrenItems2[];
    isCollapsed?: boolean;
}

export interface ChildrenItems2 {
    path?: string;
    title?: string;
    type?: string;
}

// Menu Items
export const ROUTES: RouteInfo[] = [
    {
        path: '/patients',
        title: 'Pazienti',
        type: 'sub',
        isCollapsed: true,
        icontype: 'fas fa-user-injured text-purple',
        role: ['SUPERADMIN', 'ADMIN', 'OPERATOR', 'DOCTOR'],
        children: [
            {path: '/patients', title: 'Anagrafiche', type: 'link'},
            {path: '/warnings', title: 'Allarmi', type: 'link'}
        ]
    },
    {
        path: '/doctors',
        title: 'Medici',
        type: 'link',
        icontype: 'fas fa-user-md text-green',
        role: ['SUPERADMIN', 'ADMIN', 'OPERATOR', 'DOCTOR']
    },
    {
        path: '/measurements',
        title: 'Misurazioni',
        type: 'link',
        icontype: 'fas fa-chart-line text-primary',
        role: ['SUPERADMIN', 'ADMIN', 'OPERATOR', 'DOCTOR']
    },
    {
        path: '/group_measurements',
        title: 'Gruppo di misurazioni',
        type: 'link',
        icontype: 'fas fa-procedures text-primary',
        role: ['SUPERADMIN']
    },
    {
        path: '/asl',
        title: 'Asl',
        type: 'link',
        icontype: 'fas fa-hospital text-red',
        role: ['SUPERADMIN', 'ADMIN', 'OPERATOR']
    },
    {
        path: '/operation_center',
        title: 'Centrale Operativa',
        type: 'link',
        icontype: 'fas fa-warehouse text-orange',
        role: ['SUPERADMIN']
    },
    {
        path: '/devices',
        title: 'Sensori',
        type: 'link',
        icontype: 'fab fa-bluetooth text-blue',
        role: ['SUPERADMIN', 'ADMIN']
    },
    {
        path: '/kits',
        title: 'Kit',
        type: 'link',
        icontype: 'fas fa-first-aid text-brown',
        role: ['SUPERADMIN']
    },
    {
        path: '/packs',
        title: 'Pack',
        type: 'link',
        icontype: 'fas fa-boxes text-brown',
        role: ['SUPERADMIN', 'ADMIN']
    },
    {
        path: '/hubs',
        title: 'Hub',
        type: 'link',
        icontype: 'fas fa-database text-brown',
        role: ['SUPERADMIN', 'ADMIN']
    },
    {
        path: '/users',
        title: 'Utenti',
        type: 'link',
        icontype: 'fas fa-users text-info',
        role: ['SUPERADMIN', 'ADMIN']
    },
    {
        path: '/fine_linea',
        title: 'Fine Linea',
        type: 'link',
        icontype: 'fas fa-users text-info',
        role: ['WORKER']
    }
];

@Component({
    selector: 'mad-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public isCollapsed = true;
    public role: string

    constructor(
        private router: Router,
        private store: Store<{ loggeduser: LoggedUser }>
    ) {
        store.pipe(select('loggeduser')).subscribe((loggeduser: LoggedUser) => {
            if (loggeduser) {
                console.log(loggeduser)
                this.role = loggeduser.role
            }
        })
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.router.events.subscribe(event => {
            this.isCollapsed = true;
        });
    }

    onMouseEnterSidenav() {
        if (!document.body.classList.contains('g-sidenav-pinned')) {
            document.body.classList.add('g-sidenav-show');
        }
    }

    onMouseLeaveSidenav() {
        if (!document.body.classList.contains('g-sidenav-pinned')) {
            document.body.classList.remove('g-sidenav-show');
        }
    }

    minimizeSidebar() {
        const sidenavToggler = document.getElementsByClassName(
            'sidenav-toggler'
        )[0];
        const body = document.getElementsByTagName('body')[0];
        if (body.classList.contains('g-sidenav-pinned')) {
            misc.sidebar_mini_active = true;
        } else {
            misc.sidebar_mini_active = false;
        }
        if (misc.sidebar_mini_active === true) {
            body.classList.remove('g-sidenav-pinned');
            body.classList.add('g-sidenav-hidden');
            sidenavToggler.classList.remove('active');
            misc.sidebar_mini_active = false;
        } else {
            body.classList.add('g-sidenav-pinned');
            body.classList.remove('g-sidenav-hidden');
            sidenavToggler.classList.add('active');
            misc.sidebar_mini_active = true;
        }
    }
}
