import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ApiService} from '../../service/ApiService';
import {GroupMeasurement} from '../../model/GroupMeasurement';

@Injectable({providedIn: 'root'})
export class GroupMeasurementsResolver implements Resolve<any> {
    constructor(private service: ApiService) {
    }

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<any> {
        try {
            const groupMeasurements: GroupMeasurement[] = await this.service.getAllGroupMeasurements(true)
            groupMeasurements.map(async group => {
                for (let i = 0; i < group.measurements.length; i++) {
                    const mes = group.measurements[i];
                    if (!(mes instanceof Object)) {
                        group.measurements[i] =
                            await this.service.getAllMeasurementsById(mes)
                    }
                }
            })
            return groupMeasurements
        } catch (e) {
            return []
        }
    }
}
