import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'formatDate'})
export class FormatDate implements PipeTransform {

    transform(date: string, format?: string): string {
        if (date) {
            return moment(date).locale('it').format('DD/MM/YY');
        }

        return ''
    }
}
