import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ApiService} from '../../service/ApiService';
import {User} from '../../model/User';

@Injectable({providedIn: 'root'})
export class UsersResolver implements Resolve<User[]> {
    constructor(private service: ApiService) {
    }

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<User[]> {
        try {
            const users = await this.service.getAllUsers(true)
            await users.filter(u => !(u.operationCenter instanceof Object) && u.operationCenter).map(async u => {
                u.operationCenter = await this.service.getOperationCenterById(u.operationCenter)
            })
            return users
        } catch (e) {
            return []
        }
    }
}
