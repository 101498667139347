import {Action, createReducer, on} from '@ngrx/store';
import {addPatient} from '../actions/patient.action';
import {Patient} from '../model/Patient';

export const patient: Patient = null;

// tslint:disable-next-line:variable-name
const _patientReducer = createReducer(patient,
    on(addPatient, (state: any, {newPatient}) => newPatient)
);

export function patientReducer(state: Patient = patient, action: Action) {
    return _patientReducer(state, action);
}
