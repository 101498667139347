import {Injectable} from '@angular/core';
import {
    Router,
    CanActivate,
    NavigationExtras,
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivateChild,
    RouterStateSnapshot, UrlTree
} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Patient} from '../model/Patient';
import {Observable} from 'rxjs';
import {LoggedUser} from '../model/LoggedUser';

export const devicesHubsUserROLE = ['OPERATOR', 'DOCTOR']
export const ROUTE = ['devices', 'hubs', 'users']

@Injectable({
    providedIn: 'root'
})

export class RoleGuardService implements CanActivate {

    constructor(
        private router: Router
    ) {
    }

    async canActivate(activedRoute: ActivatedRouteSnapshot) {
        const route = activedRoute.routeConfig.path
        const data = localStorage.getItem('auth_data')
        const user = JSON.parse(data) as LoggedUser
        if (ROUTE.includes(route) && devicesHubsUserROLE.includes(user.role)) {
            return false
        }

        return true
    }
}
