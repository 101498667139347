import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ApiService} from '../../service/ApiService';
import {User} from '../../model/User';
import {HelpRequest} from '../../model/HelpRequest';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class WarningsResolver implements Resolve<HelpRequest[]> {
    constructor(private service: ApiService) {
    }

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<HelpRequest[]> {
        try {
            const helpRequests = []
            const jsonData = localStorage.getItem('auth_data')
            const {operationCenter} = JSON.parse(jsonData)
            let helpRequest = await this.service.getAllWarnings(operationCenter.id)
            helpRequest = helpRequest.filter(help => help.warning)
            for (let i = 0; i < helpRequest.length ; i++) {
                const help = helpRequest[i]
                if (i !== 0 && helpRequest[i].patient === helpRequest[i - 1].patient.id) {
                    help.patient = helpRequest[i - 1].patient
                } else {
                    help.patient = await this.service.getPatientById(help.patient)
                }
                help.warning.time = moment(help.warning.time)
                help.patient.birthday = moment(help.patient.birthday)
                helpRequests.push(help)
            }
            return helpRequests
        } catch (e) {
            return []
        }
    }
}
