import {Injectable} from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse, HttpErrorResponse, HttpHeaders
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {LoggedUser} from '../model/LoggedUser';
import {Store} from '@ngrx/store';
import {addError} from '../actions/error.action';
import {addSuccess} from '../actions/success.action';
import {WebSocketBaseService} from '../service/WebSocketBaseService';


/** Pass untouched request through to the next request handler. */

@Injectable()
export class Interceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private socket: WebSocketBaseService,
        private store: Store) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
        if (JSON.parse(localStorage.getItem('auth_data'))) {
            const authData = JSON.parse(localStorage.getItem('auth_data')) as LoggedUser;
            req = req.clone({
                headers: new HttpHeaders({Authorization: `Bearer ${authData.token}`})
            });
        }

        req = req.clone({headers: req.headers.set('Content-Type', 'application/json')});
        req = req.clone({headers: req.headers.set('Accept', 'application/json')});

        return next.handle(req).pipe(
            map((evt: HttpResponse<any>) => {
                    if (evt instanceof HttpResponse) {
                        if (!req.url.includes('login') && (!req.method.includes('GET'))) {
                            this.store.dispatch(addSuccess({newSuccess: 'Azione effettuata con successo'}))
                        }
                        return evt;
                    }
                }
            ),
            catchError((error: HttpErrorResponse) => {
                console.error('REST Error:\t'+error);
                
                if(error) {
                    if (error.status === 401 && error.url.includes('login')) {
                        this.store.dispatch(addError({newError: 'Username o password errate'}))
                        localStorage.clear();
                        return throwError(error);
                    } else if (error.status !== 500 && error.error && error.error.error && error.error.error.length > 0) {
                        this.store.dispatch(addError({newError: error.error.error}))
                        return throwError(error);
                    } else if(error.status === 403 || error.status === 0) {
                        this.socket.close();
                        this.store.dispatch(addError({newError: 'Sessione scaduta'}));
                        localStorage.clear();
                        this.router.navigate(['login']);
                        return throwError(error);
                    } else {
                        // this.store.dispatch(addError({newError: 'Ops, qualcosa è andato storto'}))
                        return throwError(error);
                    }
                }
                
            }));
    }
}
