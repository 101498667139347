import {Action, createReducer, on} from '@ngrx/store';
import {addError, cleanError} from '../actions/error.action';
import {callLoading} from '../actions/loading.action';

export const loading = false;

// tslint:disable-next-line:variable-name
const _loadingReducer = createReducer(loading,
    on(callLoading, (state: any, {call_loading}) => call_loading),
);

export function loadingReducer(state: boolean = loading, action: Action) {
    return _loadingReducer(state, action);
}
