import {Injectable} from '@angular/core';
import {
    Router,
    CanActivate,
    NavigationExtras,
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivateChild,
    RouterStateSnapshot, UrlTree
} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Patient} from '../model/Patient';
import {Observable} from 'rxjs';
import {LoggedUser} from '../model/LoggedUser';

@Injectable({
    providedIn: 'root'
})

export class AuthGuardService implements CanActivate, CanActivateChild {

    constructor(
        private router: Router
    ) {
    }

    async canActivate(route: ActivatedRouteSnapshot) {
        const data = localStorage.getItem('auth_data')
        const user = JSON.parse(data) as LoggedUser
        if (!user.token) {
            await this.router.navigate(['login'])
            return false;
        }

        return true;
    }

    async canActivateChild(childRoute: ActivatedRouteSnapshot) {
        const data = localStorage.getItem('auth_data')
        const user = JSON.parse(data) as LoggedUser
        if (!user) {
            await this.router.navigate(['login'])
            return false;
        }
        return true;

    }
}
