import {Action, createReducer, on} from '@ngrx/store';
import {LoggedUser} from '../model/LoggedUser';
import {addUser} from '../actions/logged_user.action';

export const user: LoggedUser = null;

// tslint:disable-next-line:variable-name
const _logged_userReducer = createReducer(user,
    on(addUser, (state: any, {newUser}) => newUser)
);

export function loggeduserReducer(state: LoggedUser = user, action: Action) {
    return _logged_userReducer(state, action);
}
