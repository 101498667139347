import {Action, createReducer, on} from '@ngrx/store';
import {addError, cleanError} from '../actions/error.action';

export const error: string = null;

// tslint:disable-next-line:variable-name
const _errorReducer = createReducer(error,
    on(addError, (state: any, {newError}) => newError),
    on(cleanError, (state: any) => null)
);

export function errorReducer(state: string = error, action: Action) {
    return _errorReducer(state, action);
}
