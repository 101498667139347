import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ApiService} from '../../service/ApiService';
import {Asl} from '../../model/Asl';

@Injectable({providedIn: 'root'})
export class AslsResolver implements Resolve<any[]> {
    constructor(private service: ApiService) {
    }

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Asl[]> {
        try {
            const asls = await this.service.getAllASLs(true)
            asls.filter(a => !(a.operationCenter instanceof Object) && a.operationCenter).map(async a => {
                a.operationCenter = await this.service.getOperationCenterById(a.operationCenter)
            })
            return asls
        } catch (e) {
            return []
        }
    }
}
