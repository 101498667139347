export const apiBaseUrl = 'https://api.hub.go-health.it/'
export const wsMeasurement = 'wss://api.hub.go-health.it/ws_api/measurement'
export const wsWarning = 'wss://api.hub.go-health.it/ws_api/warning'
export const wsFineLinea = 'wss://api.hub.go-health.it/ws_api/config'

export enum SelectionType {
    single = 'single',
    multi = 'multi',
    multiClick = 'multiClick',
    cell = 'cell',
    checkbox = 'checkbox'
}

export const STATUS = ['IN_STOCK', 'TO_BE_RETURNED', 'TO_BE_RETURNED_BROKEN', 'TO_SEND', 'DELIVERED', 'BROKEN', 'RETIRED']

export const ACTIVEorNOT = ['ACTIVE', 'INACTIVE']

export const REGION = ['ABRUZZO', 'BASILICATA', 'CALABRIA', 'CAMPANIA', 'EMILIA_ROMAGNA', 'FRIULI_VENEZIA_GIULIA', 'LAZIO', 'LIGURIA', 'LOMBARIA', 'MARCHE', 'MOLISE', 'PIEMONTE', 'PUGLIA', 'SARDEGNA', 'SICILIA', 'TOSCANA', 'TRENTINO_ALTO_ADIGE', 'UMBRIA', 'VALLE_DAOSTA', 'VENETO']
export const MEASUREMENT_TYPE = [
    {
        name: 'Misurazione temperatura',
        value: 'temperature'
    },
    {
        name: 'Misurazione ossigeno',
        value: 'oxygen'
    },
    {
        name: 'Misurazione ECG',
        value: 'ecg'
    }
]
export const ROLES = ['SUPERADMIN', 'WORKER', 'ADMIN', 'OPERATOR', 'DOCTOR']

export const MESSAGES = {
    // Message to show when array is presented
    // but contains no values
    emptyMessage: 'Nessun dato da visualizzare',

    // Footer total message
    totalMessage: 'totali',

    // Footer selected message
    selectedMessage: 'selezionato'
}

export const CONTRACT_STATUS = ['ACTIVE', 'ENDED', 'CANCELLED']
